Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getApiMethod = "GET";
exports.getFormFieldApiEndPoint = "bx_block_categories/categories/form_fields";
exports.getFormFields = "/bx_block_custom_forms/custom_forms";
exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.ApiContentType = "application/json";
exports.httpPutMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.getInquiryEndpoint = "inquiry";
exports.createCustomMethod = "POST";
exports.requestQuote = "Request a Quote";
exports.sType =  "Service Type";
exports.fillInformation =  "Fill the information as much as possible to continue your enquiry.";
exports.provisionCost = "Provisional Cost";
exports.addOn = "Inc. Add-ons ";
exports.production = "Production";
exports.cancel = "Cancel"
exports.subTotal = "Sub Total";
exports.requiredinfo = "Required Information";
exports.defaultCoverage = "Default Coverage";
exports.addOnsPackage = "Add-ons : Event filming Package";
exports.virtualPackage = "Add-ons : Virtual Package ";
exports.additionalNotes = "Add Additional Notes";
exports.projectNotes = "Additional Notes"
exports.continueReview = "Continue to Review";
exports.saveLater = "Save for Later";
exports.serviceRequire = "What services do you require?";
exports.services = "Coverage";
exports.additionalServices = "Additional Services";
exports.additionalSubCategories = "Additional Services: Choose Package below"
exports.uploadFiles = "Upload Files";
exports.uploadText = "Drag and drop files here"; 
exports.uploadSubtitle = "Upload files from your computer or drag it directly below."
exports.reviewSubmission =  "Review Before Submission";
exports.durationTypes =  "Please choose the duration you require.";
exports.submitInfo =  "Check the information you have entered before submitting.";
exports.default = "Default Coverage";
exports.categoryAPIEndPoint = "/bx_block_categories/categories/index_no_bespoke?no_bespoke=true";
exports.enquiriesAPIEndPoint = "/bx_block_categories/inquiries?status={approved}";
exports.saveformEndPoint = "save_inquiry";
exports.uploadAttacmentEndPoint = "upload_attachment"
exports.manageAddistionalService = "manage_additional_services";
exports.tellUsProject = "Tell us more about your project";
exports.enquires = "Enquiries";
exports.allenquiries = "All Enquiries";
exports.approvedEnq = "Approved Enquiries";
exports.pendingEnq = "Pending Enquiries";
exports.draftEnq = "Draft Enquiries";
exports.duplicateFileTitle = "Duplicated Files!"
exports.duplicateFileSubtitle = "It seems that you are trying to upload a file that you have already uploaded. Please select a new file and try again."
exports.updateProjectDescription = "upload_project_note"
exports.inquiryNotFoundMessage = "Something's not right. The requested enquiry cannot be found. Please check the enquiry ID and try again."
exports.AVInfoMessage = "AV Packages include Audio and Visual, therefore this add-on has been deselected. Please deselect AV Packages if you only wish to proceed with Audio or Visual Packages"
exports.AudioMainServMessage = "If you require both Audio and Visual Packages, please start with AV Packages as your primary service. Please click 'Make Changes' if you wish to proceed."
exports.createCustomFormEndpoint = "create_inquiry";
exports.modalOopsTitle = "Oops!"
exports.meetWithManagerTitle= "Please Get in Touch"
exports.meetWithManagerMessage= "For more than three days of coverage,  please contact your Account Manager for a more competitive quote."
exports.stagesOfContent = "How many stages of content do you want Audio/Video/AV support?"
exports.homeText1 = "Home"
exports.stagesOfContentError =  'For more than three stages of content, please contact your Account Manager for a more competitive quote.' 
exports.error2 = "Error 2"
exports.uploadHeader = "Please upload your files"
exports.unExpectedError = "Something went wrong. Please refresh and try again and contact your Account Manager if the problem persists."
exports.supsendedModalTitle = "Account Suspended";
exports.deactivatedTitle = "Account Deactivated"
exports.supspendedModalOkBtn = "OK";
exports.suspendedUserEndPoint =  "/account_block/accounts/check_company_suspension";
exports.noFilesAdded = "No Files Added"
// Customizable Area End